// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-sheet-classes_rowLine__lpRiy {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/HeroSheet/сomponents/HeroSheetClasses/hero-sheet-classes.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;;IAEpB,WAAW;AACf","sourcesContent":[".rowLine {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 4px;\n\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowLine": `hero-sheet-classes_rowLine__lpRiy`
};
export default ___CSS_LOADER_EXPORT___;
