// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abilities-icon_root__Kcqms {
    font-family: DescentSymbols, sans-serif !important;
    margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/icons/AbilitiesIcon/abilities-icon.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,iBAAiB;AACrB","sourcesContent":[".root {\n    font-family: DescentSymbols, sans-serif !important;\n    margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `abilities-icon_root__Kcqms`
};
export default ___CSS_LOADER_EXPORT___;
