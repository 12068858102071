// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hero-sheet_hero-container__Hev3x {
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    border-radius: 4px;

    min-width: 500px;
    max-width: 500px;
    min-height: 480px;
}

.hero-sheet_hero-container-mobile__4G0NE {
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 4px;

    width: 100%;
    height: auto;
}

.hero-sheet_szh-accordion__item-content__BBQRV {
    transition: all 200ms;
}

.hero-sheet_checkboxLine__gOlyh {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeroSheet/hero-sheet.module.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,4BAA4B;;IAE5B,kBAAkB;;IAElB,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,4BAA4B;;IAE5B,kBAAkB;;IAElB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB","sourcesContent":["\n.hero-container {\n    background-position: top;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    border-radius: 4px;\n\n    min-width: 500px;\n    max-width: 500px;\n    min-height: 480px;\n}\n\n.hero-container-mobile {\n    background-position: top;\n    background-size: cover;\n    background-repeat: no-repeat;\n\n    border-radius: 4px;\n\n    width: 100%;\n    height: auto;\n}\n\n.szh-accordion__item-content {\n    transition: all 200ms;\n}\n\n.checkboxLine {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero-container": `hero-sheet_hero-container__Hev3x`,
	"hero-container-mobile": `hero-sheet_hero-container-mobile__4G0NE`,
	"szh-accordion__item-content": `hero-sheet_szh-accordion__item-content__BBQRV`,
	"checkboxLine": `hero-sheet_checkboxLine__gOlyh`
};
export default ___CSS_LOADER_EXPORT___;
