// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-game-party_root__HLvfZ {
    padding: 89px 20px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/create-game-party.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;;IAEtB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,6BAA6B;IAC7B,iBAAiB;AACrB","sourcesContent":[".root {\n    padding: 89px 20px;\n    width: 100%;\n    box-sizing: border-box;\n\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-evenly;\n    min-height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `create-game-party_root__HLvfZ`
};
export default ___CSS_LOADER_EXPORT___;
