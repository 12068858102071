// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.plot-deck_plotDeckLine__-4MUc {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
}

.plot-deck_plotCardsColumn__8P-mV {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 8px;
}

.plot-deck_br__\\+qxJx {
    display: flex;
    align-items: center;

    height: 100%;
    width: 40px;

    background-color: hsl(0, 0%, 100%);
    border-color: black;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    font-family: DescentFont, sans-serif !important;
    justify-content: center;

    box-sizing: border-box;

    padding: 0 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OverlordBench/components/PlotDeck/plot-deck.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,YAAY;IACZ,WAAW;;IAEX,kCAAkC;IAClC,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;;IAEjB,+CAA+C;IAC/C,uBAAuB;;IAEvB,sBAAsB;;IAEtB,cAAc;AAClB","sourcesContent":["\n.plotDeckLine {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 4px;\n}\n\n.plotCardsColumn {\n    display: grid;\n    grid-auto-flow: row;\n    grid-row-gap: 8px;\n}\n\n.br {\n    display: flex;\n    align-items: center;\n\n    height: 100%;\n    width: 40px;\n\n    background-color: hsl(0, 0%, 100%);\n    border-color: black;\n    border-radius: 4px;\n    border-style: solid;\n    border-width: 1px;\n\n    font-family: DescentFont, sans-serif !important;\n    justify-content: center;\n\n    box-sizing: border-box;\n\n    padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plotDeckLine": `plot-deck_plotDeckLine__-4MUc`,
	"plotCardsColumn": `plot-deck_plotCardsColumn__8P-mV`,
	"br": `plot-deck_br__+qxJx`
};
export default ___CSS_LOADER_EXPORT___;
