// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.campaign-setup_listRow__2bbw9 {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
}

.campaign-setup_checkbox__86Htk {
    display: grid;
    grid-auto-flow: row;
    justify-content: space-around;
}
`, "",{"version":3,"sources":["webpack://./src/components/OverlordBench/components/CampaignSetup/campaign-setup.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":["\n.listRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 4px;\n}\n\n.checkbox {\n    display: grid;\n    grid-auto-flow: row;\n    justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listRow": `campaign-setup_listRow__2bbw9`,
	"checkbox": `campaign-setup_checkbox__86Htk`
};
export default ___CSS_LOADER_EXPORT___;
