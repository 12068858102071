// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.change-gold-modal_root__6ZPcZ {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    width: 100%;
}

.change-gold-modal_column__BCCbA {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.change-gold-modal_button__NHcTq {
    height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/GoldButton/components/ChangeGoldModal/change-gold-modal.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,4BAA4B;IAC5B,qBAAqB;IACrB,kBAAkB;;IAElB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n.root {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-template-rows: 1fr auto;\n    grid-column-gap: 15px;\n    grid-row-gap: 15px;\n\n    width: 100%;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n}\n\n.button {\n    height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `change-gold-modal_root__6ZPcZ`,
	"column": `change-gold-modal_column__BCCbA`,
	"button": `change-gold-modal_button__NHcTq`
};
export default ___CSS_LOADER_EXPORT___;
