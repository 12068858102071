// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/img/icons/scrollIcon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scroll-icon_root__Uk-gG {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
}

.scroll-icon_img__\\+HvlM {
    position: absolute;
    top: 0;
    left: 0;

    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;

    width: 100%;
    height: 100%;
}

.scroll-icon_background__LpUtw {
    background: radial-gradient(circle, rgba(53, 61, 173, 1) 30%, rgba(22, 52, 91, 1) 100%) padding-box;
    filter: drop-shadow(0 5px 5px rgba(22, 52, 91, 1));
    border-radius: 100%;
    width: 90%;
    height: 90%;

    position: relative;
    z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/icons/ScrollIcon/scroll-icon.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;;IAEP,mDAAqD;IACrD,wBAAwB;;IAExB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mGAAmG;IACnG,kDAAkD;IAClD,mBAAmB;IACnB,UAAU;IACV,WAAW;;IAEX,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["\n.root {\n    position: relative;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    width: 50px;\n    height: 50px;\n}\n\n.img {\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    background: url(/src/assets/img/icons/scrollIcon.png);\n    background-size: contain;\n\n    width: 100%;\n    height: 100%;\n}\n\n.background {\n    background: radial-gradient(circle, rgba(53, 61, 173, 1) 30%, rgba(22, 52, 91, 1) 100%) padding-box;\n    filter: drop-shadow(0 5px 5px rgba(22, 52, 91, 1));\n    border-radius: 100%;\n    width: 90%;\n    height: 90%;\n\n    position: relative;\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `scroll-icon_root__Uk-gG`,
	"img": `scroll-icon_img__+HvlM`,
	"background": `scroll-icon_background__LpUtw`
};
export default ___CSS_LOADER_EXPORT___;
