// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.choose-player-buttons_content__oqGpv {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 15px;

    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ChoosePlayerButtons/choose-player-buttons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB,WAAW;AACf","sourcesContent":["\n.content {\n    display: grid;\n    grid-auto-flow: row;\n    grid-row-gap: 15px;\n\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `choose-player-buttons_content__oqGpv`
};
export default ___CSS_LOADER_EXPORT___;
