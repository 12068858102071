// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.monster-showcase_swiper__uxxcY {
    width: 100%;
    padding-bottom: 65px;
}

.monster-showcase_swiperArrowContainer__kvBd1 {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OverlordBench/components/MonsterShowcase/monster-showcase.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;;IAE9B,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,UAAU;;IAEV,YAAY;AAChB","sourcesContent":["\n.swiper {\n    width: 100%;\n    padding-bottom: 65px;\n}\n\n.swiperArrowContainer {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 1;\n\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": `monster-showcase_swiper__uxxcY`,
	"swiperArrowContainer": `monster-showcase_swiperArrowContainer__kvBd1`
};
export default ___CSS_LOADER_EXPORT___;
