// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal_modalRoot__qSsqy {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    height: 100vh;
    padding: 77px 15px 25px;

    background: rgba(0, 0, 0, 0.7);

    touch-action: none;
}

.modal_modalContent__wa66a {
    height: auto;
    width: 100%;
}

.modal_closeIcon__fFqeR {
    position: absolute;
    right: 12px;
    top: 14px;

    background-color: white;
    border-radius: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.module.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,UAAU;;IAEV,aAAa;IACb,uBAAuB;;IAEvB,8BAA8B;;IAE9B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;;IAET,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n.modalRoot {\n    box-sizing: border-box;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 2;\n\n    height: 100vh;\n    padding: 77px 15px 25px;\n\n    background: rgba(0, 0, 0, 0.7);\n\n    touch-action: none;\n}\n\n.modalContent {\n    height: auto;\n    width: 100%;\n}\n\n.closeIcon {\n    position: absolute;\n    right: 12px;\n    top: 14px;\n\n    background-color: white;\n    border-radius: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalRoot": `modal_modalRoot__qSsqy`,
	"modalContent": `modal_modalContent__wa66a`,
	"closeIcon": `modal_closeIcon__fFqeR`
};
export default ___CSS_LOADER_EXPORT___;
