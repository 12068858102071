// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/img/buttons/gold/gold.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gold-button_root__ZmoHr {
    display: flex;
    align-items: center;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(0 5px 20px rgba(230, 152, 67, 1));
    /*background: radial-gradient(circle, rgba(230,152,67,1) 50%, rgba(126,73,41,1) 100%);*/

    height: 50px;
    width: 179px;
    justify-self: center;

    transition: all 100ms;
    box-sizing: border-box;
    padding-left: 27px;
}

.gold-button_goldNumber__D\\+vlZ {
    font-size: 18px;
    text-align: center;
    font-family: DescentFont, sans-serif !important;
    color: hsl(0, 0%, 20%);

    width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/components/GoldButton/gold-button.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;;IAEnB,yDAA8D;IAC9D,wBAAwB;IACxB,4BAA4B;IAC5B,qDAAqD;IACrD,uFAAuF;;IAEvF,YAAY;IACZ,YAAY;IACZ,oBAAoB;;IAEpB,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,+CAA+C;IAC/C,sBAAsB;;IAEtB,WAAW;AACf","sourcesContent":["\n.root {\n    display: flex;\n    align-items: center;\n\n    background-image: url('/src/assets/img/buttons/gold/gold.png');\n    background-size: contain;\n    background-repeat: no-repeat;\n    filter: drop-shadow(0 5px 20px rgba(230, 152, 67, 1));\n    /*background: radial-gradient(circle, rgba(230,152,67,1) 50%, rgba(126,73,41,1) 100%);*/\n\n    height: 50px;\n    width: 179px;\n    justify-self: center;\n\n    transition: all 100ms;\n    box-sizing: border-box;\n    padding-left: 27px;\n}\n\n.goldNumber {\n    font-size: 18px;\n    text-align: center;\n    font-family: DescentFont, sans-serif !important;\n    color: hsl(0, 0%, 20%);\n\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `gold-button_root__ZmoHr`,
	"goldNumber": `gold-button_goldNumber__D+vlZ`
};
export default ___CSS_LOADER_EXPORT___;
