// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hero-sheet-items_itemLine__YsuTJ {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    grid-column-gap: 4px;
}

.hero-sheet-items_buttonColumn__G0PUk {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    row-gap: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeroSheet/сomponents/HeroSheetItems/hero-sheet-items.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;IAEvB,WAAW;IACX,aAAa;AACjB","sourcesContent":["\n.itemLine {\n    display: grid;\n    grid-template-columns: 1fr auto auto;\n    align-items: center;\n    grid-column-gap: 4px;\n}\n\n.buttonColumn {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    width: 100%;\n    row-gap: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemLine": `hero-sheet-items_itemLine__YsuTJ`,
	"buttonColumn": `hero-sheet-items_buttonColumn__G0PUk`
};
export default ___CSS_LOADER_EXPORT___;
