// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/img/buttons/overlord/border.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/assets/img/bg/spotsBG.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.suggest-translation-button_root__RU60f {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 20px;

    align-self: center;

    margin: 0 auto;
    padding: 20px;

    width: calc(100vw - 40px);

    box-sizing: border-box;

    border: 10px solid;
    border-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 100 / 35px round;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-clip: padding-box;
    background-size: contain;
}

.suggest-translation-button_translateIcon__8ovhL {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 29px;
    height: 100%;
    max-width: 40px;

    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    box-sizing: border-box;

    padding: 0 8px;
}

.suggest-translation-button_translateIconDisabled__rnsQh {
    opacity: 0.3;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SuggestTranslationButton/suggest-translation-button.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB,kBAAkB;;IAElB,cAAc;IACd,aAAa;;IAEb,yBAAyB;;IAEzB,sBAAsB;;IAEtB,kBAAkB;IAClB,sEAAiF;IACjF,yDAAuD;IACvD,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;;IAEf,kCAAkC;IAClC,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;;IAEjB,sBAAsB;;IAEtB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":["\n.root {\n    display: grid;\n    grid-auto-flow: row;\n    grid-row-gap: 20px;\n\n    align-self: center;\n\n    margin: 0 auto;\n    padding: 20px;\n\n    width: calc(100vw - 40px);\n\n    box-sizing: border-box;\n\n    border: 10px solid;\n    border-image: url('/src/assets/img/buttons/overlord/border.png') 100 / 35px round;\n    background-image: url('/src/assets/img/bg/spotsBG.png');\n    background-clip: padding-box;\n    background-size: contain;\n}\n\n.translateIcon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    min-height: 29px;\n    height: 100%;\n    max-width: 40px;\n\n    background-color: hsl(0, 0%, 100%);\n    border-color: hsl(0, 0%, 80%);\n    border-radius: 4px;\n    border-style: solid;\n    border-width: 1px;\n\n    box-sizing: border-box;\n\n    padding: 0 8px;\n}\n\n.translateIconDisabled {\n    opacity: 0.3;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `suggest-translation-button_root__RU60f`,
	"translateIcon": `suggest-translation-button_translateIcon__8ovhL`,
	"translateIconDisabled": `suggest-translation-button_translateIconDisabled__rnsQh`
};
export default ___CSS_LOADER_EXPORT___;
