// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner_loadingSpinner__v0D0n {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
}

.loading-spinner_spinner__-Y5iM {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    animation: loading-spinner_spin__pN6wu 1s linear infinite;
}

@keyframes loading-spinner_spin__pN6wu {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/loading-spinner.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,yDAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".loadingSpinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-self: center;\n    width: 100%;\n}\n\n.spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-top: 4px solid #3498db;\n    border-radius: 50%;\n    width: 140px;\n    height: 140px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": `loading-spinner_loadingSpinner__v0D0n`,
	"spinner": `loading-spinner_spinner__-Y5iM`,
	"spin": `loading-spinner_spin__pN6wu`
};
export default ___CSS_LOADER_EXPORT___;
