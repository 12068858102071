// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hero-sheet-name_select__K9rvD {
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    box-sizing: border-box;

    font-size: 18px;
    text-align: center;
    color: hsl(0, 0%, 20%);

    font-family: DescentFont, sans-serif !important;
}

.hero-sheet-name_formColumn__Q8I-j {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 20px;

    width: 100%;
}

.hero-sheet-name_heroType__JmSrq {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/HeroSheet/сomponents/HeroSheetName/hero-sheet-name.module.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,kCAAkC;IAClC,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;;IAEjB,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,sBAAsB;;IAEtB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;;IAEtB,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;;IAEpB,WAAW;AACf","sourcesContent":["\n.select {\n    transition: all 100ms;\n    background-color: hsl(0, 0%, 100%);\n    border-color: hsl(0, 0%, 80%);\n    border-radius: 4px;\n    border-style: solid;\n    border-width: 1px;\n\n    width: 100%;\n    height: fit-content;\n    box-sizing: border-box;\n\n    font-size: 18px;\n    text-align: center;\n    color: hsl(0, 0%, 20%);\n\n    font-family: DescentFont, sans-serif !important;\n}\n\n.formColumn {\n    display: grid;\n    grid-auto-flow: row;\n    grid-row-gap: 20px;\n\n    width: 100%;\n}\n\n.heroType {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 4px;\n\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `hero-sheet-name_select__K9rvD`,
	"formColumn": `hero-sheet-name_formColumn__Q8I-j`,
	"heroType": `hero-sheet-name_heroType__JmSrq`
};
export default ___CSS_LOADER_EXPORT___;
